<template>
  <div class="mine">
    <mt-header :title="$t('mine')">
      <call-phone slot="right" />
    </mt-header>
    <div class="user">
      <div class="userIcon">
        <img src="../assets/home_logo.png">
      </div>
      <div class="title">{{ user.name || '---' }}</div>
      <div class="phone">{{ user.phone }}</div>
    </div>
    <div class="content">
      <div class="box">
        <div class="item" @click="$router.push('/mine/repayRecord')">
          <img src="../assets/payment_icon.png" >
          <div>{{ $t('repayment') }}</div>
        </div>
        <div class="item" @click="$router.push('/mine/orderHistory')">
          <img src="../assets/history_bills_icon.png" >
          <div>{{ $t('orderHistory') }}</div>
        </div>
      </div>
      <!-- <mt-cell :title="$t('repayment')" is-link to="/mine/repayRecord">
        <img slot="icon" src="../assets/repayment.png" width="16" style="margin-right: 10px;">
      </mt-cell>
      <mt-cell :title="$t('orderHistory')" is-link to="/mine/orderHistory">
        <img slot="icon" src="../assets/historicalOrders.png" width="16" style="margin-right: 10px;">
      </mt-cell> -->
      <!-- <mt-cell :title="$t('bankCard')" is-link to="/mine/bankCard">
        <img slot="icon" src="../assets/bankCard.png" width="18" style="margin-right: 8px;">
      </mt-cell>
      <mt-cell :title="$t('inviteFriends')" is-link to="/mine/inviteFriends">
        <img slot="icon" src="../assets/inviteFriends.png" width="20" style="margin-right: 6px;">
      </mt-cell>
      <mt-cell :title="$t('discountCoupon')" is-link to="/mine/discountCoupon">
        <img slot="icon" src="../assets/coupon.png" width="19" style="margin-right: 7px;">
      </mt-cell>
      <mt-cell :title="$t('myPromotion')" is-link to="/mine/myPromotion">
        <img slot="icon" src="../assets/myPromotion.png" width="19" style="margin-right: 7px;">
      </mt-cell> -->
      <!-- <mt-cell :title="$t('aboutUs')" is-link to="/mine/aboutUs">
        <img slot="icon" src="../assets/aboutUs.png" width="17" style="margin-right: 9px;">
      </mt-cell>
      <mt-cell :title="$t('help')" is-link to="/mine/help">
        <img slot="icon" src="../assets/help.png" width="17" style="margin-right: 9px;">
      </mt-cell> -->
      <div class="buttons">
        <mu-button full-width color="#E7AD5C" textColor="#192C4E" @click="onLogOut">{{ $t("logOut") }}</mu-button>
      </div>
    </div>
    <tab-bar />
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import tabBar from '@/components/tabBar.vue'
import { logout } from '../utils/api'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'Mine',
  components: {
    tabBar,
    callPhone
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["SET_ACCESS_TOKEN", "SET_USER"]),
    async onLogOut() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.$axios({
        url: logout,
        method: 'DELETE',
      })
        .then(() => {})
        .catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
      this.SET_ACCESS_TOKEN();
      this.SET_USER({});
      this.$router.push('/user/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.mine {
  min-height: 100vh;
  .user {
    background-color: #18252A;
    text-align: center;
    padding: 25px;
    .userIcon {
      border-radius: 62px;
      width: 62px;
      height: 62px;
      background-color: white;
      display: inline-block;
      img {
        width: 46px;
        font-size: 0px;
        margin-top: 8px;
      }
    }
    .title {
      color: white;
      font-size: 15px;
      margin-bottom: 6px;
    }
    .phone {
      color: white;
      font-size: 13px;
    }
  }
  > .content {
    position: relative;
    margin-top: 40px;
    background: #F8F8F8;
    min-height: 300px;
    &::before {
      content: '';
      position: absolute;
      height: 50px;
      top: -41px;
      left: 0;
      width: 100%;
      background: url(../assets/home_bottom_bg.png);
      background-size: 100%;
      background-color: #2A1B3F;
      z-index: -1;
    }
    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .item {
      position: relative;
      width: 120px;
      min-height: 100px;
      background: white;
      border-radius: 15px;
      text-align: center;
      font-size: 14px;
      color: #333230;
      margin-left: 22px;
      margin-right: 22px;
      margin-top: 22px;
      img {
        width: 30px;
        margin-top: 10px;
      }
    }
    .mint-cell {
      &:last-child {
        margin-left: -25px;
        padding-left: 25px;
      }
      &:first-child {
        /deep/ .mint-cell-wrapper {
          background-image: none;
        }
      }
      /deep/ .mint-cell-text {
        font-size: 14px;
        color: #757575;
      }
    }
    /deep/ .mint-cell-wrapper {
      padding-left: 0px;
    }
  }
  .buttons {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  /deep/ .mu-raised-button {
    border-radius: 8px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
</style>
